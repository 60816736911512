import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

// Routing components
import PrivateRoute from './components/routing/PrivateRoute';

// Context
import { RelyingPartyProvider } from './context/RelyingPartyContext';

// Layout components
import Navbar from './components/layout/Navbar';

// Auth components
import Login from './components/auth/Login';

// User components
import ChangeEmail from './components/user/ChangeEmail';
import ChangeEmailSuccess from './components/user/ChangeEmailSuccess';
import ChangePassword from './components/user/ChangePassword';
import ChangePasswordSuccess from './components/user/ChangePasswordSuccess';
import ForgotPassword from './components/user/ForgotPassword';
import ResetPassword from './components/user/ResetPassword';

import Lookup from './components/callers/Lookup';
import VerificationResult from './components/callers/VerificationResult';

// popup windows
import ProofsReview from './components/windows/ProofsReview';
import MobileVerifications from './components/windows/MobileVerifications';

// For AAD authentications
import { MsalProvider } from '@azure/msal-react';

const renderRouter = () => {
  return <>
    <Router>
      <RelyingPartyProvider>
        <Navbar />
        <Toaster />
        <Switch>
          <Route        exact path='/'                                      component={Login} />
          <Route        exact path='/forgot-password'                       component={ForgotPassword} />
          <Route              path='/reset-password/:agentId/:oneTimeToken' component={ResetPassword} />
          <PrivateRoute exact path='/change-email'                          component={ChangeEmail} />
          <Route        exact path='/change-email-success'                  component={ChangeEmailSuccess} />
          <PrivateRoute exact path='/change-password'                       component={ChangePassword} />
          <Route        exact path='/change-password-success'               component={ChangePasswordSuccess} />
          <PrivateRoute exact path='/lookup'                                component={Lookup} />
            {/* 'caller-queue' is a legacy feature and deprecated, use 'lookup' going forward.
                Some clients may be using the 'caller-queue' URL to open IDgo Agent */}
          <PrivateRoute exact path='/caller-queue'                          component={Lookup} />
          <PrivateRoute exact path='/auth-result/:verificationRequestId'    component={VerificationResult} />
          <Route        exact path='/proofs-review'                         component={ProofsReview} />
          <Route        exact path='/mobile-verifications'                  component={MobileVerifications} />
        </Switch>
      </RelyingPartyProvider>
    </Router>
  </>
};

const App = ({ msalInstance }) => {
  if (msalInstance) {
    return (<MsalProvider instance={msalInstance}>{renderRouter()}</MsalProvider>);
  }
  return (renderRouter());
};

export default App;