import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as queryString from 'query-string';
import moment from 'moment';

//Utils
import { Modal, ModalHeader, ModalBody, ModalFooter, Input, FormFeedback } from 'reactstrap';
import
{ getMobileVerifications
, mobileVerifyWhitelistCreate
, mobileVerifyWhitelistGet
} from '../../utils/services';
import { getTokenClaims, setWindowSize } from '../../utils/session';

//Styles
import styles from './MobileVerifications.module.css';

let whitelistInterval;

function MobileVerifications() {
  const history = useHistory();
  const [mobileNumber, setMobileNumber] = useState();
  const [verifications, setVerifications] = useState();
  const [whitelist, setWhitelist] = useState();
  const [counterInfo, setCounterInfo] = useState();
  const [activeMinutes, setActiveMinutes] = useState(60);
  const [createdBy, setCreatedBy] = useState('missing');

  const [whitelistReasonModal, setWhitelistReasonModal] = useState(false);
  const [whitelistModalFeedback, setWhitelistModalFeedback] = useState(false);
  const [whitelistReason, setWhitelistReason] = useState('');

  useEffect(() => {
    async function _getAgentName() {
      const {name, agentFirstName, agentLastName} = await getTokenClaims(['name','agentFirstName','agentLastName']);
      // AAD tokens will have a 'name' claim, IDgo tokens have 'agentFirstName' and 'agentLastName'
      const agentName = name || `${agentFirstName||'missing'} ${agentLastName||'missing'}`;
      setCreatedBy(agentName);
    };
    _getAgentName();
  }, []);

  useEffect(() => {
    if (!mobileNumber && history.location.search){
      const mobile_no_qs = getQueryStringParam(history.location.search, 'm');
      if (mobile_no_qs) {
        setMobileNumber(mobile_no_qs);
      }
    }
    async function _getMobileVerifications () {
      var startOfToday = moment().startOf('day').toISOString();
      const result = await getMobileVerifications({mobileNumber, startDatetime: startOfToday});
      if (result?.status !== 200) return;
      setVerifications(result.data);
    };
    if (mobileNumber) {
      _getMobileVerifications();
    }
  }, [mobileNumber, history.location.search, setVerifications]);

  useEffect(() => {
    async function _getMobileVerifyWhitelistGet () {
      await fetchMobileVerifyWhitelist();
    };
    if (mobileNumber) {
      _getMobileVerifyWhitelistGet();
    }
    return () => {
      if (!whitelistInterval) return;
      clearInterval(whitelistInterval);
      whitelistInterval = undefined;
    }
    // eslint-disable-next-line
  }, [mobileNumber]);

  useEffect(() => {
    const onResize = () => {
      setWindowSize('mobileVerificationsWinSize', {innerWidth: window.innerWidth, innerHeight: window.innerHeight, screenX: window.screenX, screenY:window.screenY});
    }
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, []);

  const getQueryStringParam = (qs, param) => {
    const params = queryString.parse(qs);
    return params[param] || undefined;
  }

  const activeMinutesChange = (event) => {
    setActiveMinutes(event.target.value);
  };  

  const fetchMobileVerifications = async (startDatetime) => {
    const result = await getMobileVerifications({mobileNumber, startDatetime});
    if (result?.status!==200) return;
    setVerifications(result.data);
  };

  const fetchMobileVerifyWhitelist = async () => {
    const result = await mobileVerifyWhitelistGet({mobileNumber});
    if (result?.status !== 200 || !result?.data) {
      setWhitelist(undefined);
    } else {
      setWhitelist(result.data);
      if (result.data?.status === 'active' && result.data?.expirationDtm) {
        if (whitelistInterval) {
          clearInterval(whitelistInterval);
        }
        whitelistInterval = setInterval(() => {
          var now      = new Date().getTime();
          var duration = new Date(result.data?.expirationDtm) - now;
          if (duration < 0) {
            setWhitelist({...result.data, status:'expired'});
            clearInterval(whitelistInterval);
            whitelistInterval = undefined;
            return;
          }
          var hours   = Math.floor((duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          var minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
          var seconds = Math.floor((duration % (1000 * 60)) / 1000);
          setCounterInfo({hours, minutes, seconds});
        });
      }
    }
  };
  const reasonCancelModal = () => {
    setWhitelistReasonModal(false);
    setWhitelistModalFeedback(false);
  };

  const onWhitelistReasonChange = (e) => {
    setWhitelistReason(e.target.value);
  };

  const whitelistConfirmWithInfo = async () => {
    if (!whitelistReason || whitelistReason.trim() === '') {
      setWhitelistModalFeedback(true);
      return;
    }
    setWhitelistReasonModal(false);

    await mobileVerifyWhitelistCreate({mobileNumber, activeMinutes, createdBy, reason:whitelistReason});
    await fetchMobileVerifyWhitelist();

    setWhitelistModalFeedback(false);
    setWhitelistReason('');
  };

  const onActivityToday = async () => {
    fetchMobileVerifications(moment().startOf('day').toISOString());
  };

  const onActivityLast30 = async () => {
    fetchMobileVerifications(moment().subtract(30, 'd').toISOString());
  };

  const onWhitelistClick = async () => {
    setWhitelistReasonModal(true);
  };

  const renderResult = (row) => {
    if (row.result==='Blocked') return <div className={styles.resultFailed}>{row.result}</div>
    if (row.result==='Success') return <div className={styles.resultSuccess}>{row.result}</div>
    return <div className={styles.resultPending}>{row.result}</div>
  };

  const renderFlag = (flag, lastItem) => {
    const flags = 
      { is_abuser         : 'Abuser'
      , is_attacker       : 'Attacker'
      , is_bogon          : 'Bogon'
      , is_cloud_provider : 'Cloud Provider'
      , is_proxy          : 'Proxy'
      , is_relay          : 'Relay'
      , is_tor            : 'TOR'
      , is_exit           : 'Exit'
      , is_vpn            : 'VPN'
      , is_anonymous      : 'Anonymous'
      , is_threat         : 'Threat'
      };
    const flagSpan = <span className={styles.securityFlag}>{flags[flag]}</span>;
    if (lastItem) return flagSpan
    return <span>{flagSpan}, </span>
  };

  const renderSecurityFlags = (flagsString) => {
    const flagsArray = JSON.parse(flagsString);
    return <span>{ flagsArray.map((f, i) => renderFlag(f, (flagsArray.length-1) === i)) }</span>
  };

  const renderReason = (row) => {
    if (!row?.failedDescription || !row?.failedRuleFactResult) {
      return <>
          <div className={styles.reasonMissing}></div>
        </>
    }
    if (row.failedRuleName === 'ipDistanceMiles_Exceeded') {
      return <>
          <div className={styles.reasonDesc}>
            <span><b>{row.failedRuleFactResult}</b> miles {row.failedDescription}</span>
            <span className={styles.popupContent}>
              <div>Login device detected at <b>{`${row.loginCity} ${row.loginState}`}</b> <small>(ip: {row.loginIp})</small></div>
              <div>Mobile device detected at <b>{`${row.mobileCity} ${row.mobileState}`}</b> <small>(ip: {row.mobileIp})</small></div>
            </span>
          </div>
        </>
    }
    if (row.failedRuleName === 'ipLoginDeviceSecurityFlag_Detected') {
      return <>
          <div className={styles.reasonDesc}>
            <span>{row.failedDescription} {renderSecurityFlags(row.failedRuleFactResult)}</span>
          </div>
        </>
    }
    return <>
        <div className={styles.reasonDesc}>
          <span>{row.failedDescription} <b>{row.failedRuleFactResult}</b></span>
        </div>
      </>
  };

  const renderWhitelistPopup = () => {
    return <>
        <span className={styles.popupContent}>
          <div>Created by <b>{whitelist?.createdBy}</b> on {moment.utc(whitelist.createdDtm).local().format('MMM Do YYYY, h:mm:ss a')}</div>
          <div>Reason: {whitelist?.reason}</div>
        </span>
      </>
  };

  const renderWhitelist = () => {
    if (!whitelist) return <></>;
    if (whitelist?.status === 'expired') {
      return <div className={styles.whitelistCounter}>
          <span>Whitelist has expired</span>
          {renderWhitelistPopup()}
        </div>
    }
    if (!counterInfo) return <></>; // wait for the timer to set the counterInfo object
    let remainingTime = `${counterInfo?.seconds} seconds`;
    if (counterInfo?.minutes !== 0 ) remainingTime = `${counterInfo?.minutes} minutes ${remainingTime}`;
    if (counterInfo?.hours   !== 0 ) remainingTime = `${counterInfo?.hours} hours ${remainingTime}`;
    return <>
        <div className={styles.whitelistCounter}>
          <span>Whitelist expires in {remainingTime}</span>
          {renderWhitelistPopup()}
        </div>
      </>
  };

  return (
    <div className={styles.Window}>
      <div className={styles.containerWrapper}>
        <div className={styles.toolBar}>
          <div>
            <button className={`${styles.buttonCommon} ${styles.buttonToday}`} onClick={onActivityToday}>Today</button>
            <button className={`${styles.buttonCommon} ${styles.buttonLast30}`} onClick={onActivityLast30}>Last 30 Days</button>
            <button className={`${styles.buttonCommon} ${styles.buttonClose}`} onClick={() => window.close()}>Close</button>
          </div>
          <div className={styles.whitelistControls}>
            <label for="activeMinutes">Choose the number of hours</label>
            <select  className={styles.selectMinutes} name="activeMinutes" id="activeMinutes" onChange={activeMinutesChange}>
              <option value="60">1</option>
              <option value="120">2</option>
              <option value="180">3</option>
              <option value="240">4</option>
            </select>            
            <span>then click the Whitelist button</span>
            <button className={`${styles.buttonCommon} ${styles.buttonWhitelist}`} onClick={onWhitelistClick}>Whitelist</button>
          </div>
        </div>
        <div>
        { renderWhitelist() }
        </div>
        <div className={styles.containerScroller}>
          <div className={styles.activityGridContainer}>
            { verifications && verifications.map((row, index) => {
              const cur_dt  = moment.utc(row.createdDtm).local().format('MMMM Do, YYYY');
              const last_dt = (index===0)
                  ? ''
                  : moment.utc(verifications[index-1].createdDtm).local().format('MMMM Do, YYYY')
                  ;
              const date_hdr = (cur_dt!==last_dt)
                  ? <div className={styles.header}>{cur_dt}</div>
                  : '' 
                  ;
              return <>
                      {date_hdr}
                      <div className={styles.createdDtm}>{moment.utc(row.createdDtm).local().format('h:mm a')}</div>
                      {renderResult(row)}
                      {renderReason(row)}
                    </>
            })}
          </div>
        </div>
      </div>
      <Modal isOpen={whitelistReasonModal} backdrop={false}>
        <ModalHeader>Whitelist account</ModalHeader>
        <ModalBody>
          <p style={{ fontSize: '11pt' }}>
            Please enter reason for whitelisting this account.
          </p>
          <Input
            placeholder='Reason'
            style={{ width: '100%', fontSize: '11pt' }}
            onChange={onWhitelistReasonChange}
            invalid={whitelistModalFeedback}
          ></Input>
          <FormFeedback>Reason required!</FormFeedback>
        </ModalBody>
        <ModalFooter style={{ paddingTop: '0px', borderTopWidth: '0px' }}>
          <button className='btn btn-primary btn-xs' name='modal-button' onClick={whitelistConfirmWithInfo}>
            OK
          </button>
          <button className='btn btn-light btn-xs' onClick={reasonCancelModal}>
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default MobileVerifications;
