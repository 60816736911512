import React, { useState, useEffect } from 'react';

// Utils
import { getTokenClaims } from '../../utils/session';

//Styles
import styles from './AccountToolsPanel.module.css';

const AccountToolsPanel = (props) => {
  const [isAutoEnroller, setIsAutoEnroller] = useState();

  useEffect(() => {
    async function __getRolesClaim() {
      const {roles} = await getTokenClaims(['roles']);
      if (!roles) return;
      setIsAutoEnroller(roles.includes('auto-enroller'));
    };
    __getRolesClaim();
  }, []);

  return (
    <div>
      { props.canEnroll && isAutoEnroller ?
        <button className={`btn ${styles.enrollButton}`} onClick={props.onEnroll} data-testid='button-enroll'>Enroll</button> : ''
      }
      { props.canVerify ? 
        <button className={`btn btn-primary ${styles.authenticateButton}`} onClick={props.onAuthentication} data-testid='button-authentication'>Authenticate</button> : ''
      }
    </div>
  );
};

export default AccountToolsPanel;
