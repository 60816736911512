import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as queryString from 'query-string';

// Helpers
import { onClickFocus } from '../../utils/helpers';

//Context
import { useRelyingParty } from '../../context/RelyingPartyContext';

// Components
import AccountStatus from './AccountStatus';

// Utils
import { getRelyingParty } from '../../utils/services';
import { formatMobileNumber } from '../../utils/helpers';

// Material components
import { IconButton } from '@mui/material';
// Material icons
import ClearIcon from '@mui/icons-material/Clear';

//Styles
import styles from './Lookup.module.css';

const Lookup = () => {
  const history = useHistory();
  const {relyingParty, setRelyingParty} = useRelyingParty();
  const [formattedMobileNumber, setFormattedMobileNumber] = useState('');
  const [lookupDisabled, setLookupDisabled] = useState(false);
  
  const removeMobileNumberFormatting = (inputValue) => {
    // Remove non-numeric characters
    return inputValue.replace(/\D/g, '');
  };

  const validateAndFormatMobileNumber = (mobileNum) => {
    setFormattedMobileNumber(formatMobileNumber(mobileNum));
  };

  useState(() => {
    if ( ! history?.location?.search) return;
    const qs = queryString.parse(history.location.search);
    if ( ! qs?.mobileNumber || isNaN(qs.mobileNumber)) return;
    validateAndFormatMobileNumber(qs?.mobileNumber);
  }, [history.location.search]);

  useState(() => {
    async function __getRelyingPartyInfo() {
      const rpResult = await getRelyingParty();
      setRelyingParty(rpResult?.data);
    };
    __getRelyingPartyInfo();  

    if ( ! history?.location?.search) return;
    const qs = queryString.parse(history.location.search);
    if ( ! qs?.mobileNumber || isNaN(qs.mobileNumber)) return;

    // looks like IDgo Agent was launched with a query-string to pre-populate the mobile number

    const handleUnload = (_e) => {
      if (window?.opener) { // for poped up windows
        if (relyingParty?.agentPopupOrigin) {
          window.opener.postMessage({result: 'browser-closed', source:'IDgoAgent'}, relyingParty.agentPopupOrigin);
          return;
        }
        console.warn(`Lookup: relyingParty.agentPopupOrigin not set - unable to postMessage to opener`);
      }
      if (window?.parent) { // for iframed up windows
        console.warn(`Lookup: posting data: {result:'browser-closed'}`);
        window.parent.postMessage({result: 'browser-closed', source:'IDgoAgent'}, '*');
        return;
      }
    };
    window.addEventListener('unload', handleUnload);
    return () => {
      window.removeEventListener('unload', handleUnload);
    }
  }, [relyingParty]);

  const clearLookupFormHandler = () => {
    setFormattedMobileNumber('');
    document.getElementById(`search-input`).focus();
  };

  return (
    <>
      <div className={styles.inputGroup}>
        <input
          id='search-input'
          type='tel'
          className={styles.accountInput}
          placeholder='Mobile number'
          name='search'
          value={formattedMobileNumber}
          onChange={(e) => validateAndFormatMobileNumber(e.target.value)}
          onClick={(e) => onClickFocus(e)}
          autoFocus
          autoComplete='off'
          maxLength='14'
          data-testid='search-input'
          disabled={lookupDisabled}
        />
        <label htmlFor='search-input'>Mobile number</label>
        <IconButton
          className={styles.clearIcon}
          style={{marginTop:'-4px'}}
          id='basic-button'
          onClick={clearLookupFormHandler}
          disabled={lookupDisabled}
        >
          <ClearIcon />
        </IconButton>
      </div>

      { formattedMobileNumber.length > 13 && (
        <AccountStatus
          phone={removeMobileNumberFormatting(formattedMobileNumber)} 
          disableLookup={setLookupDisabled}
        />
      )}
    </>
  );
};

export default Lookup;